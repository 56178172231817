import React from 'react';
import { useSelector } from 'react-redux';
import { Panel } from "@vkontakte/vkui";

import Locations from '../../components/Mobile/Locations';

import '../../../styles/Mobile/page.scss';

const Home = ({ id }) => {
    const locations = useSelector((state) => state.locations.list);
    return (
        <Panel id={id} theme="white" className="mobile-page">
            <div className="mobile-page__head">
                <div className="mobile-page__title">Главные культурные точки Санкт-Петербурга</div>
                <div className="mobile-page__subtitle">
                    Мы создали подборку интересных виртуальных туров по главным культурным точкам Северной столицы
                </div>
            </div>
            <Locations data={locations} />
        </Panel>
    );
};

export default Home;
