import React from 'react';

const Zoom = () => {
    return (
        <div className="excursion__zoom btn-bg">
            <div
                className="btn"
                onMouseDown={() => window.krpano.set('fov_moveforce', -1)}
                onMouseUp={() => window.krpano.set('fov_moveforce', 0)}
            >
                <div className="icon-plus" />
            </div>
            <div className="sep" />
            <div
                className="btn"
                onMouseDown={() => window.krpano.set('fov_moveforce', 1)}
                onMouseUp={() => window.krpano.set('fov_moveforce', 0)}
            >
                <div className="icon-minus" />
            </div>
        </div>
    );
};

export default Zoom;
