import React from 'react';
import '../../../styles/Mobile/photo.scss';

const Photo = ({ link }) => (
    <div>
        <div
            className="mobile-photo"
            style={{
                backgroundImage: `url(${link})`,
            }}
        />
    </div>
);

export default Photo;
