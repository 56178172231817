import React from 'react';
import { HorizontalScroll } from '@vkontakte/vkui';
import '../../../styles/Mobile/photo.scss';

import Photo from "./Photo";

const Gallery = ({ photos = [] }) => (
    <HorizontalScroll>
        <div className="mobile-photo__container">
            {photos.map((photo) => (
                <Photo
                    key={photo}
                    link={photo}
                />
            ))}
        </div>
    </HorizontalScroll>
);

export default Gallery;
