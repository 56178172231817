import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@vkontakte/vkui';

import { openLocation as openLocationAction } from '../../store/locations/actions';
import { vkStat } from "vk-stat/vk";

import '../../../styles/Desktop/location-content.scss';
import '../../../styles/Desktop/location-new.scss'

const PointItem = ({ id, name, description, place: { address, link }, site, image }) => {
    const dispatch = useDispatch();

    const openLocation = useCallback(() => {
        dispatch(openLocationAction(id));
        vkStat('main', `excursion${id}`);
    }, [dispatch, id]);

    return (
        <div className="location-new__container">
            <div className="location-new">
                <div className="location-new__header">
                    <div
                        className="location-new__image"
                        style={{
                            backgroundImage: `url(${image})`,
                        }}
                    />
                    <div className="location-new__angle">360°</div>
                </div>
                <div className="location-new__content">
                    <div className="location-new__name">{name}</div>
                    <div className="location-new__description">{description}</div>
                    <div className="location-new__info">
                        <div className="location-new__info-item">
                            <div className="icon icon-place-w" />
                            <a href={link} target="_blank" rel="noopener noreferrer">{address}</a>
                        </div>
                        <div className="location-new__info-item">
                            <div className="icon icon-globe-w" />
                            <a href={`http://${site}`} target="_blank" rel="noopener noreferrer">{site}</a>
                        </div>
                    </div>
                </div>
                <div className="location-new__footer">
                    <Button onClick={openLocation}>Начать экскурсию</Button>
                </div>
            </div>
        </div>
    );
};

export default PointItem;
