import * as types from './actionsTypes';
import { goPanel } from '../navigator/actions';

export const loadLocations = (locations = []) => {
    return {
        type: types.LOCATIONS_LOAD,
        locations,
    };
};

export const selectLocation = (id = null) => {
    return {
        type: types.LOCATIONS_SELECT,
        id,
    };
};

export const openLocation = (id = null) => (dispatch) => {
    // change active excursion
    dispatch(selectLocation(id));

    // go to `excursion` panel
    dispatch(goPanel('excursion'));
};

export const exitFromLocation = () => (dispatch) => {
    // change active excursion
    dispatch(selectLocation());

    // go to `home` panel
    dispatch(goPanel('home'));
};
