import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, HorizontalScroll } from '@vkontakte/vkui';

import '../../../styles/Mobile/modal.scss';
import '../../../styles/Mobile/info.scss';

import ExcursionObject from "../Desktop/ExcursionObject";

const ExcursionObjects = ({ onClose }) => {
    const [ closed, setClosed ] = useState(false);
    const allObjects = useSelector((state) => state.objects.list);
    const places = useSelector((state) => state.places.list);
    const [place, setPlace] = useState({});
    const [objects, setObjects] = useState([]);

    useEffect(() => {
        const place_id = window.krpano.get("xml.scene");
        setPlace(places.find((place) => place.id === place_id) || {});
    }, [places]);

    useEffect(() => {
        const filteredObjects = allObjects.filter((obj) => {
            return (place.objects || []).indexOf(obj.id) !== -1;
        });

        setObjects(filteredObjects);

    }, [place, allObjects]);

    return (
        <div className={`modal__overlay ${closed && 'modal__overlay-hide'}`}>
            <div className="mobile-modal__wrap">
                <div className="mobile-info__description">
                    <div className="mobile-info__title">{place.title}</div>
                    <div className="mobile-info__info">
                        {place.description && place.description.split('\n').map((text) => (
                            <p style={{ marginTop: 0 }}>{text}</p>)
                        )}
                    </div>
                    {objects.length > 0 && (
                        <div className="mobile-info__object">
                            <div className="mobile-info__subtitle">объекты в этой локации</div>
                            <HorizontalScroll>
                                <div className="mobile-info__objects">
                                    {objects.map((obj) => (
                                        <ExcursionObject
                                            key={obj.id}
                                            image={obj.image}
                                            title={obj.name}
                                        />
                                    ))}
                                </div>
                            </HorizontalScroll>
                        </div>
                    )}
                    <div className="mobile-info__footer">
                        <Button
                            onClick={() => {
                                setClosed(true);
                                setTimeout(() => onClose(), 500);
                            }}
                        >
                            <div className="icon-cancel-black" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExcursionObjects;
