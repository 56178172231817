import * as types from './actionsTypes';

const initialState = {
    list: [],
    selected: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.OBJECTS_LOAD: {
            const { objects } = action;
            return {
                ...state,
                list: objects,
            };
        }

        case types.OBJECTS_OPEN: {
            const { obj_id } = action;
            return {
                ...state,
                selected: state.list.find((obj) => obj.id === obj_id) || null,
            };
        }

        case types.OBJECTS_CLOSE: {
            return {
                ...state,
                selected: null,
            };
        }

        default: return state;
    }
};
