import { combineReducers } from 'redux';

// reducers
import navigator from './navigator';
import locations from './locations';
import places from './places';
import objects from './objects';

export default combineReducers({
    navigator,
    locations,
    places,
    objects,
});
