import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Panel, Button, FixedLayout, Separator } from '@vkontakte/vkui';
import '../../../styles/Mobile/about.scss';

// Components
import Gallery from '../../components/Mobile/Gallery';

// Icons
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';

// Actions
import { goPanel } from '../../store/navigator/actions';
import formatPhoneNumber from "../../utils/formatPhone";

const About = ({ id }) => {
    const dispatch = useDispatch();
    const goBack = useCallback(() => dispatch(goPanel("home")), [dispatch]);
    const go3D = useCallback(() => dispatch(goPanel("excursion")), [dispatch]);
    const location = useSelector((state) => state.locations.active);

    return (
        <Panel id={id} theme="white">
            <YMaps>
                <div className="mobile-about block-page">
                    <div
                        className="mobile-about__image"
                        style={{
                            backgroundImage: `url(${location.cover})`,
                        }}
                    />
                    <div className="mobile-about__header">
                        <div className="btn-back" onClick={goBack}>
                            <Icon28ChevronBack width={20} height={28} fill="fff" />
                        </div>
                    </div>
                    <div className="mobile-about__content">
                        <div className="mobile-about__title">{location.name}</div>
                        <div className="mobile-about__description">
                            {location.description}
                        </div>
                        <Gallery photos={location.photos} />
                        <div className="about__info" style={{ paddingBottom: 0 }}>
                            <Separator style={{ marginBottom: 13 }} />
                            <div className="about__info__title">Адрес</div>
                            <Map
                                className="about__map"
                                defaultState={{
                                    center: [
                                        location.place.lon,
                                        location.place.alt
                                    ],
                                    zoom: location.place.zoom || 14,
                                }}
                                modules={['control.ZoomControl']}
                            >
                                <Placemark defaultGeometry={[location.place.lon, location.place.alt]} />
                            </Map>
                            <div className="mobile-about__map-text">
                                <div className="about__icon icon-place" />
                                <span>{location.place.address}</span>
                            </div>
                        </div>
                        <div className="about__info">
                            <Separator style={{ marginBottom: 13 }} />
                            <div className="about__info__title">Информация</div>
                            <div className="mobile-about__info__item no-align">
                                <div className="about__icon icon-time" />
                                <div className="mobile-about__info__text">
                                    {location.worktime.map((time) => (
                                        <p key={time}>{time}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="mobile-about__info__item">
                                <div className="about__icon icon-ticket" />
                                <div className="mobile-about__info__text">{`${location.ticket_price} ₽`}</div>
                            </div>
                            <div className="mobile-about__info__item">
                                <div className="about__icon icon-phone" />
                                <a href={`tel:79999999999`} className="mobile-about__info__text">
                                    {formatPhoneNumber(location.contacts.phone)}
                                </a>
                            </div>
                            <div className="mobile-about__info__item">
                                <div className="about__icon icon-message" />
                                <a href={`mailto:${location.contacts.email}`} className="mobile-about__info__text">
                                    {location.contacts.email}
                                </a>
                            </div>
                            <div className="mobile-about__info__item">
                                <div className="about__icon icon-globe" />
                                <a
                                    href={`https://${location.contacts.site}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="mobile-about__info__text"
                                >
                                    {location.contacts.site}
                                </a>
                            </div>
                        </div>
                        <div style={{ height: 76 }} />
                        <FixedLayout vertical="bottom">
                            <div className="mobile-about__footer">
                                <Button size="xl" onClick={go3D}>
                                    Начать экскурсию
                                </Button>
                            </div>
                        </FixedLayout>
                    </div>
                </div>
            </YMaps>
        </Panel>
    );
};

export default About;
