import * as types from './actionsTypes';

const initialState = {
    activePanel: 'home',
    popout: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.NAVIGATOR_GO_PANEL: {
            const { panel } = action;
            return {
                ...state,
                activePanel: panel,
            };
        }

        case types.NAVIGATOR_SHOW_POPOUT: {
            const { popout } = action;
            return {
                ...state,
                popout,
            };
        }

        default: return state;
    }
};
