import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Tabs, TabsItem } from '@vkontakte/vkui';

import '../../../styles/Mobile/navigation.scss';
import MapBlock from "../Desktop/MapBlock";

const ExcursionMap = ({ onClose }) => {
    const [ closed, setClosed ] = useState(false);
    const location = useSelector((state) => state.locations.active);
    const places = useSelector((state) => state.places.list.filter((place) => {
        return location.map.places.indexOf(place.id) !== -1;
    }));

    const [currentScene, setCurrentScene] = useState(window.krpano.get("xml.scene"));
    const [tabIndex, setTab] = useState(places.find((x) => x.id === currentScene).tab);
    const tab = location.map.tabs.find((x) => x.id === tabIndex);

    const doClose = () => {
        setClosed(true);
        setTimeout(() => onClose(), 500);
    };

    return (
        <div className={`modal__overlay ${closed && 'modal__overlay-hide'}`}>
            <div className="modal__wrap">
                <div className="mobile-navigation">
                    <div className="mobile-navigation__content">
                        <div className="tabs-inner">
                            <div
                                className="mobile-navigation__map"
                                style={{
                                    backgroundImage: `url('${tab.background}')`,
                                    width: `${tab.width || 400}px`,
                                    height: `${tab.height || 423}px`
                                }}
                            >
                                {places.filter((x) => x.tab === tabIndex).map((block) => (
                                    <MapBlock
                                        key={block.id}
                                        style={block.id === currentScene && "darken"}
                                        onSelect={() => {
                                            setCurrentScene(block.id);
                                            doClose();
                                        }}
                                        id={block.id}
                                        x={block.x}
                                        y={block.y}
                                        w={block.w}
                                        h={block.h}
                                        icon={block.icon}
                                        indicator={block.indicator}
                                        indicatorInsets={block.indicatorInsets}
                                    />
                                ))}
                            </div>
                        </div>
                        <Tabs type="buttons" className="mobile-tabs-header">
                            {location.map.tabs.map((tab) => (
                                <TabsItem
                                    key={tab.id}
                                    onClick={() => setTab(tab.id)}
                                    selected={tabIndex === tab.id}
                                >
                                    {tab.name}
                                </TabsItem>
                            ))}
                        </Tabs>
                    </div>
                    <div className="mobile-info__footer">
                        <Button
                            onClick={doClose}
                        >
                            <div className="icon-cancel-black" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExcursionMap;
