import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@vkontakte/vkui';

import { goPanel } from '../../store/navigator/actions';
import { vkStat } from "vk-stat/vk";

const ExcursionTop = ({ showInstruction, showUI, changeUIVisible }) => {
    const [checked, setChecked] = useState(false);
    const toggle = () => setChecked(checked => !checked);

    const dispatch = useDispatch();
    const location = useSelector((state) => state.locations.active);
    const back = useCallback(() => {
        dispatch(goPanel('home'));
        vkStat(`excursion${location.id}`, 'exit');
    }, [dispatch]);

    return (
        <div className="excursion__top">
            <Button className="btn-bg" onClick={back}>
                Выйти
            </Button>
            <Button
                className="btn-bg excursion__top-left"
                style={checked ? { opacity: .6 } : {}}
                onClick={toggle}
            >
                <div className="icon-menu" />
            </Button>
            {checked && (
                <div className="excursion__menu">
                    <Button level="tertiary" onClick={() => {
                        toggle(false);
                        changeUIVisible();
                    }}>
                        {showUI ? "Скрыть интерфейс" : "Показать интерфейс"}
                    </Button>
                    <Button level="tertiary" onClick={() => {
                        toggle(false);
                        showInstruction();
                    }}>
                        Инструкция
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ExcursionTop;
