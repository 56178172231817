import React from 'react';
import '../../../styles/Desktop/photo.scss';

const Photo = ({ link }) => (
    <div
        className="photo"
        style={{
            backgroundImage: `url(${link})`,
        }}
    />
);

export default Photo;
