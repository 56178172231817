import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import { View } from "@vkontakte/vkui";
import { bridgeGetAccessTokenAndSave } from "vk-stat/vk";

// Desktop
import DesktopHome from "./js/containers/Desktop/Home";
import DesktopAbout from "./js/containers/Desktop/About";
import DesktopExcursion from "./js/containers/Desktop/Excursion";

// Mobile
import MobileHome from "./js/containers/Mobile/Home";
import MobileAbout from "./js/containers/Mobile/About";
import MobileExcursion from "./js/containers/Mobile/Excursion";

const App = () => {
	const activePanel = useSelector((state) => state.navigator.activePanel);
	const popout = useSelector((state) => state.navigator.popout);

	useEffect(() => {
		bridgeGetAccessTokenAndSave();
	}, []);

	if (window.is_desktop) {
		return (
			<View activePanel={activePanel} header={false}>
				<DesktopHome id="home" />
				<DesktopAbout id="about" />
				<DesktopExcursion id="excursion" />
			</View>
		);
	}

	return (
		<View activePanel={activePanel} header={false} popout={popout}>
			<MobileHome id="home" />
			<MobileAbout id="about" />
			<MobileExcursion id="excursion" />
		</View>
	);
};

export default App;
