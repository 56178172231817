import React from 'react';
import { useSelector } from 'react-redux';
import { Panel } from "@vkontakte/vkui";

import Locations from '../../components/Desktop/Locations';

import '../../../styles/Desktop/page.scss';
import '../../../styles/Desktop/page-new.scss';

const Home = ({ id }) => {
    const locations = useSelector((state) => state.locations.list);
    return (
        <Panel id={id} theme="white" className="page-new">
            <div className="page-new__background">
                <div className="page-new__title">
                    Главные культурные точки
                    <br/>
                    Санкт-Петербурга
                </div>
                <div className="page-new__subtitle">
                    Мы создали подборку интересных виртуальных туров
                    <br />
                    по главным культурным точкам Северной столицы
                </div>
                <Locations data={locations} />
            </div>
        </Panel>
    );
};

export default Home;
