import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, TabsItem } from '@vkontakte/vkui';
import MapBlock from './MapBlock';

import '../../../styles/Desktop/modal.scss';
import '../../../styles/Desktop/navigation.scss';

const ExcursionMap = ({ close }) => {
    const [ closed, setClosed ] = useState(false);
    const location = useSelector((state) => state.locations.active);
    const places = useSelector((state) => state.places.list.filter((place) => {
        return location.map.places.indexOf(place.id) !== -1;
    }));

    const [currentScene, setCurrentScene] = useState(window.krpano.get("xml.scene"));
    const [tabIndex, setTab] = useState(places.find((x) => x.id === currentScene).tab);
    const tab = location.map.tabs.find((x) => x.id === tabIndex);

    const doClose = () => {
        setClosed(true);
        setTimeout(() => close(), 500);
    };

    return (
        <div className={`modal__overlay ${closed && 'modal__overlay-hide'}`}>
            <div className="modal">
                <div className="modal__content navigation">
                    <div className="modal__block">
                        <div className="navigation__header">
                            <div className="navigation__title">Карта навигации</div>
                            <div
                                className="icon-close navigation__close"
                                onClick={doClose}
                            />
                        </div>
                        <div className="navigation__content">
                            <Tabs type="buttons" className="tabs-header">
                                {location.map.tabs.map((tab) => (
                                    <TabsItem
                                        key={tab.id}
                                        onClick={() => setTab(tab.id)}
                                        selected={tabIndex === tab.id}
                                    >
                                        {tab.name}
                                    </TabsItem>
                                ))}
                            </Tabs>
                            <div className="navigation__separator" />
                            <div className="tabs-inner">
                                <div
                                    className="navigation__map"
                                    style={{
                                        backgroundImage: `url('${tab.background}')`,
                                        width: `${tab.width || 400}px`,
                                        height: `${tab.height || 423}px`
                                    }}
                                >
                                    {places.filter((x) => x.tab === tabIndex).map((block) => (
                                        <MapBlock
                                            key={block.id}
                                            style={block.id === currentScene && "darken"}
                                            onSelect={() => {
                                                setCurrentScene(block.id);
                                                doClose();
                                            }}
                                            id={block.id}
                                            x={block.x}
                                            y={block.y}
                                            w={block.w}
                                            h={block.h}
                                            icon={block.icon}
                                            indicator={block.indicator}
                                            indicatorInsets={block.indicatorInsets}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExcursionMap;
