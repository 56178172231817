import * as types from './actionsTypes';

export const loadObjects = (objects = []) => {
    return {
        type: types.OBJECTS_LOAD,
        objects,
    };
};

export const openObjectInfo = (obj_id) => {
    return {
        type: types.OBJECTS_OPEN,
        obj_id: obj_id,
    };
};

export const closeObjectInfo = () => {
    return {
        type: types.OBJECTS_CLOSE,
    };
};
