import * as types from './actionsTypes';

export const goPanel = (panel) => ({
    type: types.NAVIGATOR_GO_PANEL,
    panel,
});

export const showPopout = (popout) => ({
    type: types.NAVIGATOR_SHOW_POPOUT,
    popout,
});
