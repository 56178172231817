import * as types from './actionsTypes';

const initialState = {
    list: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.PLACES_LOAD: {
            const { places } = action;
            return {
                ...state,
                list: places,
            };
        }

        default: return state;
    }
};
