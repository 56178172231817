import 'core-js/features/map';
import 'core-js/features/set';

import React from 'react';
import ReactDOM from 'react-dom';
import connect from '@vkontakte/vk-connect';

// App
import App from './App';
import { googleGtmInit } from "vk-stat/googleGtm";
import '@vkontakte/vkui/dist/vkui.css';
import './styles/Desktop/fonts.scss';
import './styles/Desktop/icons.scss';

// Api
import * as API from './js/api';
import { loadLocations } from './js/store/locations/actions';
import { loadPlaces } from './js/store/places/actions';
import { loadObjects } from './js/store/objects/actions';

// Store
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './js/store';

export const store = createStore(rootReducer, applyMiddleware(thunk));
API.loadLocations().then((response) => store.dispatch(loadLocations(response)));
API.loadPlaces().then((response) => store.dispatch(loadPlaces(response)));
API.loadObjects().then((response) => store.dispatch(loadObjects(response)));

googleGtmInit('GTM-M24HMXV');

/*
    Get config from url params
 */
const params = window.location.search.slice(1).split("&")
    .reduce((a, x) => {
        const data = x.split("=");
        return {
            ...a,
            [data[0]]: data[1],
        };
    }, {});

window.is_desktop = params["vk_platform"] === "desktop_web";


// Theme changer
connect.subscribe(({ detail: { type, data }}) => {
    if (type === "VKWebAppUpdateConfig") {
        const schemeAttribute = document.createAttribute("scheme");
        schemeAttribute.value = data.scheme ? data.scheme : "bright_light";
        document.body.attributes.setNamedItem(schemeAttribute);
    }
});

// Init VK Mini App
connect.send('VKWebAppInit');

ReactDOM.render(<Provider store={store} children={<App />} />, document.getElementById('root'));
