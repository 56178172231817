import React from 'react';

import '../../../styles/Desktop/map-block.scss';

const MapBlock = ({ id = null, w = 0, h = 0, x = 0, y = 0, style = '', onSelect, indicator = "1", icon = '/icons/default.svg', indicatorInsets = null }) => {
    let className = 'map-block';
    if (style === 'dark') {
        className += ' map-block-dark';
    }

    if (style === 'darken') {
        className += ' map-block-darken';
    }

    const openLocation = (scene) => {
        window.krpano.call(`loadscene(${scene})`);
        if (onSelect) {
            onSelect();
        }
    };

    return (
        <div
            className={className}
            style={{
                left: `${x}px`,
                top: `${y}px`,
                width: `${w}px`,
                height: `${h}px`,
                backgroundImage: `url(${icon})`,
            }}
            onClick={() => openLocation(id)}
        >
            <div
                className="map-block__indicator"
                style={{
                    marginTop: `${indicatorInsets !== null && !!indicatorInsets.top ? indicatorInsets.top : 0}px`,
                    marginLeft: `${indicatorInsets !== null && !!indicatorInsets.left ? indicatorInsets.left : 0}px`,
                }}
            >
                {indicator}
            </div>
        </div>
    );
};

export default MapBlock;
