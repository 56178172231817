import React, { useCallback } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { ActionSheet, ActionSheetItem, IS_PLATFORM_IOS } from '@vkontakte/vkui';
import '../../../styles/Mobile/menu.scss';

import ExcursionInstruction from './ExcursionInstruction';

import { goPanel, showPopout } from '../../store/navigator/actions';
import { vkStat } from "vk-stat/vk";

import Icon28OutlineAbout from '@vkontakte/icons/dist/28/info_outline';

const ExcursionTop = ({ showUI, changeUIVisible, setModal }) => {
    const dispatch = useDispatch();
    const location = useSelector((state) => state.locations.active);
    const back = useCallback(() => {
        dispatch(goPanel('home'));
        vkStat(`excursion${location.id}`, 'exit');
    }, [dispatch]);

    const openInstruction = () => {
        setModal(<ExcursionInstruction onClose={() => setModal(null)} />)
        vkStat(`excursion${location.id}`, 'manual');
    }

    const popout = (
        <ActionSheet onClose={() => dispatch(showPopout(null))}>
            <ActionSheetItem
                autoclose
                before={<div className={`icon-${showUI ? "hide" : "show"}ui`}/>}
                className="mobile-menu__item"
                onClick={() => changeUIVisible()}
            >
                {showUI ? "Скрыть интерфейс" : "Показать интерфейс"}
            </ActionSheetItem>
            <ActionSheetItem
                autoclose
                className="mobile-menu__item ActionSheetItem--last mobile-menu__item-last"
                before={<Icon28OutlineAbout fill="white"/>}
                onClick={openInstruction}
            >
               Инструкция
            </ActionSheetItem>
            {IS_PLATFORM_IOS && (
                <ActionSheetItem
                    autoclose
                    mode="cancel"
                    className="mobile-menu__item ActionSheetItem--cancel"
                >
                    Отменить
                </ActionSheetItem>
            )}
        </ActionSheet>
    );

    const openMenu = useCallback(() => dispatch(showPopout(popout)), [dispatch, popout]);

    return (
        <div className="excursion__top mobile-menu__buttons">
            <div className="mobile-menu__back" onClick={back}>
                Выйти
            </div>
            <div className="mobile-menu__button" onClick={openMenu}>
                <div className="icon-menu" />
            </div>
        </div>
    );
};

export default ExcursionTop;
