import React, { useCallback } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import {useDispatch, useSelector} from 'react-redux';
import { Panel, Button } from '@vkontakte/vkui';
import formatPhoneNumber from '../../utils/formatPhone';
import '../../../styles/Desktop/about.scss';

// Components
import Gallery from '../../components/Desktop/Gallery';

// Icons
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';

// Actions
import { goPanel } from '../../store/navigator/actions';

const About = ({ id }) => {
    const dispatch = useDispatch();
    const goBack = useCallback(() => dispatch(goPanel("home")), [dispatch]);
    const go3D = useCallback(() => dispatch(goPanel("excursion")), [dispatch]);
    const location = useSelector((state) => state.locations.active);

    return (
        <Panel id={id} theme="white">
            <YMaps>
                <div className="about block-page">
                    <div className="about__back" onClick={goBack}>
                        <Icon28ChevronBack width={16} height={16} fill="fff" />
                        Назад
                    </div>
                    <div
                        className="about__image"
                        style={{
                            backgroundImage: `url(${location.cover})`,
                        }}
                    />
                    <div className="about__wrapp">
                        <div className="about__content">
                            <div className="about__title">{location.name}</div>
                            <div className="about__description">
                                {location.description}
                            </div>
                            <Button className="about__button" onClick={go3D}>Начать экскурсию</Button>
                            <div className="about__block">
                                <div className="about__subtitle">Фотографии</div>
                                <Gallery
                                    photos={location.photos}
                                />
                            </div>
                            <div className="about__block">
                                <div className="about__subtitle">Адрес</div>
                                <Map
                                    className="about__map"
                                    defaultState={{
                                        center: [
                                            location.place.lon,
                                            location.place.alt
                                        ],
                                        zoom: location.place.zoom || 14,
                                    }}
                                    modules={['control.ZoomControl']}
                                >
                                    <Placemark defaultGeometry={[location.place.lon, location.place.alt]} />
                                </Map>
                                <div className="about__map-text">
                                    <div className="about__icon icon-place" />
                                    <span>{location.place.address}</span>
                                </div>
                            </div>
                        </div>
                        <div className="about__info">
                            <div className="about__info__title">Информация</div>
                            <div className="about__info__item no-align">
                                <div className="about__icon icon-time" />
                                <div className="about__info__text">
                                    {location.worktime.map((time) => (
                                        <p key={time}>{time}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="about__info__item">
                                <div className="about__icon icon-ticket" />
                                <div className="about__info__text">{`${location.ticket_price} ₽`}</div>
                            </div>
                            <div className="about__info__item">
                                <div className="about__icon icon-phone" />
                                <a href={`tel:79999999999`} className="about__info__text">
                                    {formatPhoneNumber(location.contacts.phone)}
                                </a>
                            </div>
                            <div className="about__info__item">
                                <div className="about__icon icon-message" />
                                <a href={`mailto:${location.contacts.email}`} className="about__info__text">
                                    {location.contacts.email}
                                </a>
                            </div>
                            <div className="about__info__item">
                                <div className="about__icon icon-globe" />
                                <a
                                    href={`https://${location.contacts.site}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="about__info__text"
                                >
                                    {location.contacts.site}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </YMaps>
        </Panel>
    );
};

export default About;
