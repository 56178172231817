import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@vkontakte/vkui';
import { vkStat } from "vk-stat/vk";

import ExcursionObject from "./ExcursionObject";

import '../../../styles/Desktop/excursion-window.scss';

const ExcursionObjects = () => {
    const location = useSelector((state) => state.locations.active);
    const allObjects = useSelector((state) => state.objects.list);
    const places = useSelector((state) => state.places.list);
    const [place, setPlace] = useState({});
    const [objects, setObjects] = useState([]);
    const [checked, setChecked] = useState(false);
    const [ closed, setClosed ] = useState(false);

    const toggle = () => {
        const place_id = window.krpano.get("xml.scene");
        setPlace(places.find((place) => place.id === place_id) || {});
        if (checked) {
            setClosed(true);
            setTimeout(() => {
                setChecked(false);
                setClosed(false);
            }, 500);
        } else {
            setChecked(true);
            vkStat(`excursion${location.id}`, 'description');
        }
    };

    useEffect(() => {
        const filteredObjects = allObjects.filter((obj) => {
            return (place.objects || []).indexOf(obj.id) !== -1;
        });

        setObjects(filteredObjects);

    }, [place, allObjects]);

    return (
        <>
            {checked && (
                <div className={`excursion-window ${closed && "excursion-window-hide"}`}>
                    <div className="excursion-window__title">{place.title}</div>
                    <div className="excursion-window__info">
                        {place.description && place.description.split('\n').map((text) => (
                            <p style={{ marginTop: 0 }}>{text}</p>)
                        )}
                    </div>
                    {objects.length > 0 && (
                        <div className="excursion-window__object">
                            <div className="excursion-window__subtitle">объекты в этой локации</div>
                            <div className="excursion-window__objects">
                                {objects.map((obj) => (
                                    <ExcursionObject
                                        key={obj.id}
                                        image={obj.image}
                                        title={obj.name}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
            <Button
                className="fixed-width"
                level="tertiary"
                before={checked ? <div className="icon-cancel" /> : <div className="icon-list" />}
                onClick={toggle}
            >
                {checked ? 'Скрыть' : 'Показать'} описание
            </Button>
        </>
    );
};

export default ExcursionObjects;
