import * as types from './actionsTypes';

const initialState = {
    list: [],
    active: null,
    scene: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.LOCATIONS_LOAD: {
            const { locations } = action;
            return {
                ...state,
                list: locations,
            };
        }

        case types.LOCATIONS_SELECT: {
            const { id } = action;
            const activeLocation = state.list.find((location) => location.id === id);
            return {
                ...state,
                active: activeLocation,
                scene: id !== null ? activeLocation.home_scene : null,
            };
        }

        default: return state;
    }
};
