import React from 'react';
import LocationItem from './LocationItem';

import '../../../styles/Mobile/locations.scss';

const Locations = ({ data }) => (
    <div className="mobile-locations">
        {data.map((location) => (
            <LocationItem
                key={location.id}
                id={location.id}
                name={location.name}
                description={location.description}
                image={location.image}
                place={location.place}
                site={location.contacts.site}
            />
        ))}
    </div>
);

export default Locations;
