import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@vkontakte/vkui';

import { openLocation as openLocationAction } from '../../store/locations/actions';
import { vkStat } from "vk-stat/vk";

import '../../../styles/Mobile/location.scss';

const PointItem = ({ id, name, description, place: { address, link }, site, image }) => {
    const dispatch = useDispatch();
    const openLocation = useCallback(() => {
        dispatch(openLocationAction(id));
        vkStat('main', `excursion${id}`);
    }, [dispatch, id]);

    return (
        <div className="mobile-location__item">
            <div className="mobile-location__header">
                <div
                    className="mobile-location__image"
                    style={{
                        backgroundImage: `url(${image})`,
                    }}
                />
                <div className="mobile-location__angle">360°</div>
            </div>
            <div className="mobile-location__content">
                <div className="mobile-location__title">{name}</div>
                <div className="mobile-location__description">{description}</div>
                <div className="mobile-location__info">
                    <div className="mobile-location__info-item">
                        <div className="icon icon-place-b" />
                        <a href={link} target="_blank" rel="noopener noreferrer">{address}</a>
                    </div>
                    <div className="mobile-location__info-item">
                        <div className="icon icon-globe-b" />
                        <a href={`http://${site}`} target="_blank" rel="noopener noreferrer">{site}</a>
                    </div>
                </div>
            </div>
            <div className="mobile-location__footer">
                <Button size="xl" onClick={openLocation}>Начать экскурсию</Button>
            </div>
        </div>
    );
};

export default PointItem;
