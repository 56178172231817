import locationsMock from './mocks/locations';
import placesMock from './mocks/places';
import objectsMock from './mocks/objects';

export const loadLocations = () => new Promise((resolve) => {
    resolve(locationsMock.items);
});

export const loadPlaces = () => new Promise((resolve) => {
    resolve(placesMock.items);
});

export const loadObjects = () => new Promise((resolve) => {
    resolve(objectsMock.items);
});
