import React, { useState } from 'react';
import { Button } from '@vkontakte/vkui';
import '../../../styles/Mobile/instruction.scss';

const ExcursionInstruction = ({ onClose }) => {
    const [ closed, setClosed ] = useState(false);
    return (
        <div className={`modal__overlay ${closed && 'modal__overlay-hide'}`}>
            <div className="modal__wrap">
                <div className="mobile-instruction">
                    <div className="mobile-instruction__title">Инструкция</div>
                    <div className="mobile-instruction__content">
                        <div className="mobile-instruction__item">
                            <div className="icon-hand" />
                            <span>Перемещение камеры</span>
                        </div>
                        <div className="mobile-instruction__item">
                            <div className="icon-zoom-mobile" />
                            <span>Приближение/отдаление</span>
                        </div>
                        <div className="mobile-instruction__item">
                            <div className="icon-list-mobile" />
                            <span>Описание текущей локации</span>
                        </div>
                        <div className="mobile-instruction__item">
                            <div className="icon-explore" />
                            <span>Карта</span>
                        </div>
                    </div>
                    <div className="mobile-info__footer">
                        <Button
                            size="xl"
                            onClick={() => {
                                setClosed(true);
                                setTimeout(() => onClose(), 500);
                            }}
                        >
                            <div className="icon-cancel-black" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExcursionInstruction;
