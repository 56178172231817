import React from 'react';

import '../../../styles/Desktop/excursion-object.scss';

const ExcursionObject = ({ title, image }) => {
    return (
        <div className="excursion-object">
            <div
                className="excursion-object__image"
                style={{
                    backgroundImage: `url(${image})`,
                }}
            />
        </div>
    );
};

export default ExcursionObject;
