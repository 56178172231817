import React, { useState } from 'react';
import '../../../styles/Desktop/instruction.scss';

const ExcursionInstruction = ({ close }) => {
    const [ closed, setClosed ] = useState(false);
    return (
        <div className={`instruction__overlay ${closed && 'instruction__overlay-hide'}`}>
            <div className="instruction">
                <div className="instruction__header">
                    <div className="instruction__title">Инструкция</div>
                    <div
                        className="instruction__close icon-close"
                        onClick={() => {
                            setClosed(true);
                            setTimeout(() => close(), 500);
                        }}
                    />
                </div>
                <div className="instruction__content">
                    <div className="instruction__item">
                        <div className="icon-move" />
                        <span>Перемещение камеры</span>
                    </div>
                    <div className="instruction__item">
                        <div className="icon-zoom" />
                        <span>Приближение/отдаление</span>
                    </div>
                    <div className="instruction__item">
                        <div className="icon-list-s" />
                        <span>Описание текущей локации</span>
                    </div>
                    <div className="instruction__item">
                        <div className="icon-compass" />
                        <span>Карта</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExcursionInstruction;
