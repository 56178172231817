import React from 'react';
import { Button } from '@vkontakte/vkui';

import ExcursionObjects from './ExcursionObjects';

const ExcursionFooter = ({ showMap }) => {
    return (
        <div className="excursion__footer">
            <ExcursionObjects />
            <Button
                className="fixed-width"
                before={<div className="icon-explore" />}
                level="tertiary"
                onClick={showMap}
            >
                Открыть карту
            </Button>
        </div>
    );
};

export default ExcursionFooter;
