import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel } from '@vkontakte/vkui';
import Krpano from 'react-krpano';

import { openObjectInfo } from '../../store/objects/actions';

import ExcursionTop from '../../components/Mobile/ExcursionTop';
import ExcursionFooter from '../../components/Mobile/ExcursionFooter';

import '../../../styles/Desktop/excursion.scss';
import '../../../styles/Desktop/pano.scss';

const Excursion = ({ id }) => {
    const dispatch = useDispatch();
    const scene = useSelector((state) => state.locations.scene);
    const [modal, setModal] = useState(null);
    const [showUI, setShowUI] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            window.krpano.hooks.ready();
        }, 1)
    }, []);

    const loadScene = () => {
        window.krpano.call(`loadscene(${scene})`);
    };

    const hooks = {
        openObjectInfo: (obj_id) => dispatch(openObjectInfo(obj_id)),
    };

    return (
        <Panel id={id} className="excursion">
            <Krpano
                xml="krpano/tour.xml"
                mounted={loadScene}
                loading="Загрузка панорамы..."
                hooks={hooks}
            />
            <ExcursionTop
                setModal={setModal}
                showUI={showUI}
                changeUIVisible={() => setShowUI((prev) => !prev)}
            />
            {showUI && <ExcursionFooter setModal={setModal} />}
            {modal}
        </Panel>
    );
};

export default Excursion;
