import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel } from '@vkontakte/vkui';
import Krpano from 'react-krpano';

import { openObjectInfo } from '../../store/objects/actions';
import { vkStat } from "vk-stat/vk";

import ExcursionTop from '../../components/Desktop/ExcursionTop';
import ExcursionMap from '../../components/Desktop/ExcursionMap';
import ExcursionInstruction from '../../components/Desktop/ExcursionInstruction';
import Zoom from '../../components/Desktop/Zoom';
import ExcursionFooter from '../../components/Desktop/ExcursionFooter';

import '../../../styles/Desktop/excursion.scss';
import '../../../styles/Desktop/pano.scss';

const Excursion = ({ id }) => {
    const dispatch = useDispatch();
    const location = useSelector((state) => state.locations.active);
    const scene = useSelector((state) => state.locations.scene);
    const [showInstruction, setShowInstruction] = useState(false);
    const [showUI, setShowUI] = useState(true);
    const [showMap, setShowMap] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            window.krpano.hooks.ready();
        }, 1)
    }, []);

    useEffect(() => {
        if (showMap) {
            vkStat(`excursion${location.id}`, 'minimap');
        }
    }, [showMap]);

    useEffect(() => {
        if (showInstruction) {
            vkStat(`excursion${location.id}`, 'manual');
        }
    }, [showInstruction]);

    const loadScene = () => {
        window.krpano.call(`loadscene(${scene})`);
    };

    const hooks = {
        openObjectInfo: (obj_id) => dispatch(openObjectInfo(obj_id)),
    };

    return (
        <Panel id={id} className="excursion">
            <Krpano
                xml="krpano/tour.xml"
                mounted={loadScene}
                loading="Загрузка панорамы..."
                hooks={hooks}
            />
            <ExcursionTop
                showUI={showUI}
                showInstruction={() => setShowInstruction(true)}
                changeUIVisible={() => setShowUI((prev) => !prev)}
            />
            {showMap && <ExcursionMap close={() => setShowMap(false)} />}
            {showInstruction && <ExcursionInstruction close={() => setShowInstruction(false)} />}
            {showUI && <Zoom />}
            {showUI && <ExcursionFooter showMap={() => setShowMap(true)} />}
        </Panel>
    );
};

export default Excursion;
