import React from 'react';
import Photo from './Photo';

const Gallery = ({ photos = [] }) => (
    <div className="photo__container">
        {photos.map((photo) => (
            <Photo
                key={photo}
                link={photo}
            />
        ))}
    </div>
);

export default Gallery;
