import React from 'react';
import { useSelector } from "react-redux";
import { Button } from '@vkontakte/vkui';
import { vkStat } from "vk-stat/vk";

import '../../../styles/Mobile/footer.scss';
import ExcursionObjects from './ExcursionObjects';
import ExcursionMap from './ExcursionMap';

const ExcursionFooter = ({ setModal }) => {
    const location = useSelector((state) => state.locations.active);

    const openDescription = () => {
        setModal(<ExcursionObjects onClose={() => setModal(null)} />);
        vkStat(`excursion${location.id}`, 'description');
    }

    const openMap = () => {
        setModal(<ExcursionMap onClose={() => setModal(null)} />);
        vkStat(`excursion${location.id}`, 'minimap');
    }

    return (
        <div className="mobile-footer">
            <Button
                onClick={openDescription}
            >
                <div className="icon-list" />
            </Button>
            <Button
                onClick={openMap}
            >
                <div className="icon-explore" />
            </Button>
        </div>
    );
};

export default ExcursionFooter;
